import { create } from '@storybook/theming/create';

export const ohmStorybookManagerTheme = create({
  base: 'light',
  // Typography
  fontBase:
    "'Poppins', 'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', sans-serif",
  fontCode: 'monospace',

  brandTitle: 'OhmConnect Storybook',
  brandUrl: '/',
  brandImage: 'https://app.ohmconnect.com/logo512.png',
  brandTarget: '_self',

  colorPrimary: '#546bf2',
  colorSecondary: '#546bf2',

  // // UI
  appBg: 'rgba(248, 249, 250, 0.5)',
  appContentBg: '#ffffff',
  appBorderColor: 'rgba(26, 33, 69, 0.1)',
  appBorderRadius: 10,

  // // Text colors
  textColor: '#192145',
  textInverseColor: '#ffffff',

  // // Toolbar default and active colors
  barTextColor: 'rgba(26, 33, 69, 0.5)',
  barBg: '#ffffff',

  // // Form colors
  inputBg: '#ffffff',
  inputBorderRadius: 4,
});
